<template>
  <div class="main">
    <div class="form">
      <div class="title">试用申请</div>
      <el-form style="margin: 0 auto;" :label-position="'top'" :model="ruleForm" :rules="rules" ref="ruleForm"
        label-width="100px" class="demo-ruleForm">
        <el-form-item label="公司名称" prop="company_name">
          <el-input v-model="ruleForm.company_name" placeholder="填写您的公司名称"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="linkman">
          <el-input v-model="ruleForm.linkman" placeholder="请填写您的姓名"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="ruleForm.phone" placeholder="请填写您的电话"> </el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="ruleForm.email" placeholder="请填写您的邮箱"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="ruleForm.password" placeholder="请填写您的注册密码"></el-input>
        </el-form-item>
        <el-form-item style="display: flex;justify-content: center;">
          <el-button type="primary" @click="submitForm('ruleForm')">提交信息</el-button>
        </el-form-item>
      </el-form>

    </div>

    <!-- 背景 -->
    <div class="bg">
      <div class="night">
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
      </div>
    </div>
    <!-- 背景end -->
  </div>
</template>
<script>
import axios from "axios"
import { Dialog } from 'vant';
import { mapState, mapMutations } from 'vuex';
import { Notify } from 'vant';
export default {
  created() {
    (function flexible(window, document) {
      function resetFontSize() {
        console.log("dsf")
        // const size = (document.documentElement.clientWidth / 360) * 12;
        // document.documentElement.style.fontSize = size + 'px';
        let size;
        if (window.matchMedia("(min-width: 768px)").matches) {
          // PC端
          size = (document.documentElement.clientWidth / 1385) * 12;
        } else {
          // 手机端
          size = (document.documentElement.clientWidth / 360) * 12;
        }
        document.documentElement.style.fontSize = size + 'px';
      }

      // reset root font size on page show or resize
      window.addEventListener('pageshow', resetFontSize);
      window.addEventListener('resize', resetFontSize);
    })(window, document);

  },
  computed: {
    ...mapState(['count']),
    fmtTitle() {
      return this.$store.company_name ? this.$store.company_name.length > 4 ? this.$store.state.company_name.clice(0, 3) + ".." : this.$store.state.company_name : ""
    }
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      console.log("dfsd")
      const reg = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/
      setTimeout(() => {
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请请正确输入手机号码!'))
        }
      }, 100)
    }
    var checkEmail = (rule, value, callback) => {//邮箱校验
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (!value) {
        return callback(new Error('邮箱不能为空!'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱格式!'))
        }
      }, 100)
    }
    return {
      baseurl: "https://shinyboy.top/QRapi/api/v1",
      test: "http://localhost:39100/QRapi/api/v1",
      testbaseurl: "https://shinyboy.top/QRapi/api/v1",
      loginUrl: "/publicity/submitInfo",
      ruleForm_: {
        company_name: "测试",
        linkman: "萨达",
        phone: "15059002663",
        email: "3412@qq.com",
        password: "123456"
      },
      ruleForm: {
        company_name: "",
        linkman: "",
        phone: "",
        email: "",
        password: ""
      },
      rules: {
        company_name: [
          { required: true, message: '请输入您的公司名称', trigger: 'blur' },
        ],
        linkman: [
          { required: true, message: '请输入联系人名称', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        // password: [
        //   { required: true, message: '请输入联系人名称', trigger: 'blur' }
        // ],
        phone: [{ required: true, trigger: 'blur', validator: checkPhone }],
        email: [{ required: true, trigger: 'blur', validator: checkEmail }],
      }
    };
  },
  methods: {
    ...mapMutations(['createcompany_name', 'createlinkman']),
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios.post(this.testbaseurl + this.loginUrl, this.ruleForm).then(res => {
            console.log(res)
            this.$store.commit("createcompany_name", this.ruleForm.company_name)
            this.$store.commit("createlinkman", this.ruleForm.linkman)
            // createcompany_name(this.ruleForm.company_name)
            // createlinkman(this.ruleForm.linkman)
            // Dialog({ message: '提交信息成功' });
            this.downloadApp()
            setTimeout(() => {
              this.$router.push("/")
            }, 1000 * 2)
            // Dialog.confirm({
            //   message: '提交信息成功，是否立即下载',
            // })
            //   .then(() => {
            //     // on confirm
            //     this.downloadApp()

            //     setTimeout(() => {
            //       this.$router.push("/")
            //     }, 1000, 1)
            //   })
            //   .catch(() => {
            //     this.$router.push("/")
            //   });
          }).catch(err => {
            let message = err.response.data.message
            Dialog({ message });
          }
          )
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    downloadApp() {
      window.open("https://public.fjcs.xyz/%E5%85%83%E5%8F%AF%E5%8F%AF.apk")
      Notify({ type: 'success', message: '信息提交成功，正在为您下载' });
    },
  }
}
</script>

<style>
.main {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow-x: hidden;
}

.form {
  width: 50%;
  /* height: 60%; */
}

.title {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 24px;
}


/* 适配 PC */
@media screen and (min-width: 768px) {

  /* 在宽度大于等于 768px 的屏幕上应用以下样式 */
  .form {
    width: 40%;
    /* height: 60%; */
  }
}

/* 适配手机 */
@media screen and (max-width: 767px) {

  /* 在宽度小于 768px 的屏幕上应用以下样式 */
  .form {
    width: 60%;
    /* height: 60%; */
  }
}

/* 背景样式start */
.bg {
  background: radial-gradient(ellipse at bottom, #111922 0%, #000000 100%);
  height: 100vh;
  overflow: hidden;
  display: flex;
  font-family: "Anton", sans-serif;
  justify-content: center;
  align-items: center;
  width: 100vw;
  position: absolute;
  top: 0;
  z-index: -99;
}

.night {
  position: relative;
  width: 100%;
  height: 200%;
  transform: rotateZ(139deg);
}

.shooting_star {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 2px;
  background: linear-gradient(-45deg, #5f91ff, rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px #699bff);
  -webkit-animation: tail 3000ms ease-in-out infinite,
    shooting 3000ms ease-in-out infinite;
  animation: tail 3000ms ease-in-out infinite,
    shooting 3000ms ease-in-out infinite;
}

.shooting_star::before {
  content: "";
  position: absolute;
  top: calc(50% - 1px * 2);
  right: 0;
  height: 2px;
  background: linear-gradient(-45deg,
      rgba(0, 0, 255, 0),
      #5f91ff,
      rgba(0, 0, 255, 0));
  transform: translateX(50%) rotateZ(45deg);
  border-radius: 100%;
  -webkit-animation: shining 3000ms ease-in-out infinite;
  animation: shining 3000ms ease-in-out infinite;
}

.shooting_star::after {
  content: "";
  position: absolute;
  top: calc(50% - 1px * 2);
  right: 0;
  height: 2px;
  background: linear-gradient(-45deg,
      rgba(0, 0, 255, 0),
      #5f91ff,
      rgba(0, 0, 255, 0));
  transform: translateX(50%) rotateZ(45deg);
  border-radius: 100%;
  -webkit-animation: shining 3000ms ease-in-out infinite;
  animation: shining 3000ms ease-in-out infinite;
  transform: translateX(50%) rotateZ(-45deg);
}

.shooting_star:nth-child(1) {
  top: calc(50% - -4px * 2);
  left: calc(50% - 248px);
  -webkit-animation-delay: 3060ms;
  animation-delay: 3060ms;
}

.shooting_star:nth-child(1)::before,
.shooting_star:nth-child(1)::after {
  -webkit-animation-delay: 3060ms;
  animation-delay: 3060ms;
}

.shooting_star:nth-child(2) {
  top: calc(50% - 180px * 2);
  left: calc(50% - 166px);
  -webkit-animation-delay: 4693ms;
  animation-delay: 4693ms;
}

.shooting_star:nth-child(2)::before,
.shooting_star:nth-child(2)::after {
  -webkit-animation-delay: 4693ms;
  animation-delay: 4693ms;
}

.shooting_star:nth-child(3) {
  top: calc(50% - -88px * 2);
  left: calc(50% - 136px);
  -webkit-animation-delay: 1178ms;
  animation-delay: 1178ms;
}

.shooting_star:nth-child(3)::before,
.shooting_star:nth-child(3)::after {
  -webkit-animation-delay: 1178ms;
  animation-delay: 1178ms;
}

.shooting_star:nth-child(4) {
  top: calc(50% - 92px * 2);
  left: calc(50% - 92px);
  -webkit-animation-delay: 9285ms;
  animation-delay: 9285ms;
}

.shooting_star:nth-child(4)::before,
.shooting_star:nth-child(4)::after {
  -webkit-animation-delay: 9285ms;
  animation-delay: 9285ms;
}

.shooting_star:nth-child(5) {
  top: calc(50% - 89px * 2);
  left: calc(50% - 92px);
  -webkit-animation-delay: 5475ms;
  animation-delay: 5475ms;
}

.shooting_star:nth-child(5)::before,
.shooting_star:nth-child(5)::after {
  -webkit-animation-delay: 5475ms;
  animation-delay: 5475ms;
}

.shooting_star:nth-child(6) {
  top: calc(50% - -164px * 2);
  left: calc(50% - 27px);
  -webkit-animation-delay: 9220ms;
  animation-delay: 9220ms;
}

.shooting_star:nth-child(6)::before,
.shooting_star:nth-child(6)::after {
  -webkit-animation-delay: 9220ms;
  animation-delay: 9220ms;
}

.shooting_star:nth-child(7) {
  top: calc(50% - -136px * 2);
  left: calc(50% - 243px);
  -webkit-animation-delay: 7942ms;
  animation-delay: 7942ms;
}

.shooting_star:nth-child(7)::before,
.shooting_star:nth-child(7)::after {
  -webkit-animation-delay: 7942ms;
  animation-delay: 7942ms;
}

.shooting_star:nth-child(8) {
  top: calc(50% - 174px * 2);
  left: calc(50% - 75px);
  -webkit-animation-delay: 8312ms;
  animation-delay: 8312ms;
}

.shooting_star:nth-child(8)::before,
.shooting_star:nth-child(8)::after {
  -webkit-animation-delay: 8312ms;
  animation-delay: 8312ms;
}

.shooting_star:nth-child(9) {
  top: calc(50% - 138px * 2);
  left: calc(50% - 281px);
  -webkit-animation-delay: 6475ms;
  animation-delay: 6475ms;
}

.shooting_star:nth-child(9)::before,
.shooting_star:nth-child(9)::after {
  -webkit-animation-delay: 6475ms;
  animation-delay: 6475ms;
}

.shooting_star:nth-child(10) {
  top: calc(50% - 25px * 2);
  left: calc(50% - 25px);
  -webkit-animation-delay: 7118ms;
  animation-delay: 7118ms;
}

.shooting_star:nth-child(10)::before,
.shooting_star:nth-child(10)::after {
  -webkit-animation-delay: 7118ms;
  animation-delay: 7118ms;
}

.shooting_star:nth-child(11) {
  top: calc(50% - 184px * 2);
  left: calc(50% - 198px);
  -webkit-animation-delay: 8570ms;
  animation-delay: 8570ms;
}

.shooting_star:nth-child(11)::before,
.shooting_star:nth-child(11)::after {
  -webkit-animation-delay: 8570ms;
  animation-delay: 8570ms;
}

.shooting_star:nth-child(12) {
  top: calc(50% - -194px * 2);
  left: calc(50% - 283px);
  -webkit-animation-delay: 1026ms;
  animation-delay: 1026ms;
}

.shooting_star:nth-child(12)::before,
.shooting_star:nth-child(12)::after {
  -webkit-animation-delay: 1026ms;
  animation-delay: 1026ms;
}

.shooting_star:nth-child(13) {
  top: calc(50% - 169px * 2);
  left: calc(50% - 42px);
  -webkit-animation-delay: 4957ms;
  animation-delay: 4957ms;
}

.shooting_star:nth-child(13)::before,
.shooting_star:nth-child(13)::after {
  -webkit-animation-delay: 4957ms;
  animation-delay: 4957ms;
}

.shooting_star:nth-child(14) {
  top: calc(50% - -114px * 2);
  left: calc(50% - 139px);
  -webkit-animation-delay: 5748ms;
  animation-delay: 5748ms;
}

.shooting_star:nth-child(14)::before,
.shooting_star:nth-child(14)::after {
  -webkit-animation-delay: 5748ms;
  animation-delay: 5748ms;
}

.shooting_star:nth-child(15) {
  top: calc(50% - 140px * 2);
  left: calc(50% - 283px);
  -webkit-animation-delay: 2809ms;
  animation-delay: 2809ms;
}

.shooting_star:nth-child(15)::before,
.shooting_star:nth-child(15)::after {
  -webkit-animation-delay: 2809ms;
  animation-delay: 2809ms;
}

.shooting_star:nth-child(16) {
  top: calc(50% - 72px * 2);
  left: calc(50% - 88px);
  -webkit-animation-delay: 8661ms;
  animation-delay: 8661ms;
}

.shooting_star:nth-child(16)::before,
.shooting_star:nth-child(16)::after {
  -webkit-animation-delay: 8661ms;
  animation-delay: 8661ms;
}

.shooting_star:nth-child(17) {
  top: calc(50% - 40px * 2);
  left: calc(50% - 273px);
  -webkit-animation-delay: 8037ms;
  animation-delay: 8037ms;
}

.shooting_star:nth-child(17)::before,
.shooting_star:nth-child(17)::after {
  -webkit-animation-delay: 8037ms;
  animation-delay: 8037ms;
}

.shooting_star:nth-child(18) {
  top: calc(50% - 176px * 2);
  left: calc(50% - 27px);
  -webkit-animation-delay: 826ms;
  animation-delay: 826ms;
}

.shooting_star:nth-child(18)::before,
.shooting_star:nth-child(18)::after {
  -webkit-animation-delay: 826ms;
  animation-delay: 826ms;
}

.shooting_star:nth-child(19) {
  top: calc(50% - -164px * 2);
  left: calc(50% - 229px);
  -webkit-animation-delay: 1822ms;
  animation-delay: 1822ms;
}

.shooting_star:nth-child(19)::before,
.shooting_star:nth-child(19)::after {
  -webkit-animation-delay: 1822ms;
  animation-delay: 1822ms;
}

.shooting_star:nth-child(20) {
  top: calc(50% - 85px * 2);
  left: calc(50% - 161px);
  -webkit-animation-delay: 5305ms;
  animation-delay: 5305ms;
}

.shooting_star:nth-child(20)::before,
.shooting_star:nth-child(20)::after {
  -webkit-animation-delay: 5305ms;
  animation-delay: 5305ms;
}

.shooting_star:nth-child(21) {
  top: calc(50% - 100px * 2);
  left: calc(50% - 300px);
  -webkit-animation-delay: 4305ms;
  animation-delay: 4305ms;
}

.shooting_star:nth-child(21)::before,
.shooting_star:nth-child(21)::after {
  -webkit-animation-delay: 4305ms;
  animation-delay: 4305ms;
}

@-webkit-keyframes tail {
  0% {
    width: 0;
  }

  30% {
    width: 100px;
  }

  100% {
    width: 0;
  }
}

@keyframes tail {
  0% {
    width: 0;
  }

  30% {
    width: 100px;
  }

  100% {
    width: 0;
  }
}

@-webkit-keyframes shining {
  0% {
    width: 0;
  }

  50% {
    width: 30px;
  }

  100% {
    width: 0;
  }
}

@keyframes shining {
  0% {
    width: 0;
  }

  50% {
    width: 30px;
  }

  100% {
    width: 0;
  }
}

@-webkit-keyframes shooting {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(300px);
  }
}

@keyframes shooting {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(300px);
  }
}

@-webkit-keyframes sky {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

@keyframes sky {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

/* 样式规则 */
@media screen and (max-width: 600px) {
  .content {
    width: 100%;
  }

  .content-title {
    font-size: 24px;
    display: flex;
    flex-direction: column;
  }

  .content-text {
    font-size: 16px;
    line-height: 20px;
    width: 70%;
  }

  .content-btnarea {
    margin-top: 35px;
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
    flex-wrap: wrap;
  }

  .content-btn {
    padding: 15px 13px;
    font-size: 13px;
    border-radius: 25px;
    line-height: 16px;
  }
}
</style>

<style scoped lang="less" src="./assets/signup.less" />
<style scoped lang="less" src="./assets/media.less" ></style>
  